<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.monitors) $modal={
      cancel:true,
      title:i18n("licence--No more monitors"), 
      msg:i18n("licence--No more monitors msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/monitors/edit")
  }

  async function bulk(){
    setRoute("/monitors/bulk")
  }

  async function edit(id){
    setRoute(`/monitors/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/monitors/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/monitors")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/monitors/get/${id}`)
    obj={ ...obj }
  }

  isSame("listMonitors","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listMonitors", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/monitors/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/monitors/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/monitors")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/monitors/tags/list')
  }

  let intervals=[ 
    { value: 1, label: "1 min"},
    { value: 5, label: "5 min"},
    { value: 15, label: "15 min"},
    { value: 30, label: "30 min"},
    { value: 60, label: "1 hour"},
    { value: 60*6 , label: "6 hour"},
    { value: 60*12 , label: "12 h"},
    { value: 60*24, label: "1 day"}
  ]

</script>


<CustomerBanner/>


<Route path="/monitors/*" pathHide="/monitors/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={$permissions.bulk?bulk:false}

      bind:filter filters={[
        { filter: {}, title:'g--All' },
        { filter: {valid:true}, title:'g--In use' },
        { filter: {status:"outage"}, title:'g--Outage' },
        { filter: {status:"maintenance"}, title:'g--Maintenance' },

      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'monitorDate', title:'g--By date'},
        { id:'status', title:'g--By status'}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--monitors" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:"status", title:"g--Status"},
      {id:"valid", title:"g--In use"},
      {id:"name", title:"g--Name"},      
      {id:"tags", title:"g--Tags", format:"tags"},
      {id:"sinceDate", title:"g--Since date", format:'shortTimeSecond'},
      {id:"monitorDate", title:"g--Next run", format:'shortTimeSecond'},
      {id:"interval", title:"g--interval", format:(i)=>getById(intervals,i,"value").label},
    ]}/>
  </div>
</Route>  


<Route path="/monitors/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="monitor" detail={obj.name} back="/monitors"/>
      
      <div class="anchor" on:click={anchor}>
        <span><I18n>General</I18n></span> |
        <span><I18n>Status</I18n></span> |
        <span><I18n>Monitor</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="General"/>
          <Input class="col-md-2" set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="reference"/>
          <Input class="col-md-6" bind:value={obj.name} required type="text" icon="fa-input-text" label="monitor name" multilang translator/>
          <Input class="col-md-2" bind:value={obj.valid} set={true} lg type="switch" icon="fa-play-circle" label="active" option="yes"/>
          <Input class="col-md-2" bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>
          <Input bind:value={obj.description} type="textarea" rows=3 icon="fa-comment" label="Monitor description"/>
 
          <Input type="hr" icon="fa-info-square" label="Status"/>
          <Input class="col-md-12" inline bind:value={obj.status} set="operational" type="radio" icon="fa-traffic-light" label="Monitor status" options={[ 
            { value:"operational", label: "operational"},
            { value:"outage", label: "outage"},
            { value:"maintenance", label: "maintenance"},
            { value:"disabled", label: "disabled"},
          ]}/>


          <Input type="hr" icon="fa-cog" label="Monitor"/>

          <Input class="col-md-9" inline bind:value={obj.monitorType} set={"url"} type="radio" icon="fa-mailbox" label="Monitor type" options={[ 
            { value: "url", label: "Check url"},
            { value: "ssl", label: "Check ssl certificate validity"},
            { value: "mx", label: "Check mx"},
            { value: "imap", label: "Check imap"}
          ]}/>
          <Input class="col-md-3" inline bind:value={obj.interval} set={5} type="select" icon="fa-mailbox" label="Monitor interval" options={intervals}/>
          <Input bind:value={obj.url} required type="url" icon="fa-link" label="monitor url"/>
      

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>