<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.pages) $modal={
      cancel:true,
      title:i18n("licence--No more pages"), 
      msg:i18n("licence--No more pages msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/pages/edit")
  }

  async function bulk(){
    setRoute("/pages/bulk")
  }

  async function edit(id){
    setRoute(`/pages/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/pages/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/pages")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/pages/get/${id}`)
    obj={ ...{style:{}, ...obj }}
  }

  isSame("listPages","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listPages", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/pages/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/pages/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/pages")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/pages/tags/list')
  }

  let services=[]
  getServices()
  async function getServices(){
    services = await api(`/app/customers/services/list`)
  }

</script>


<CustomerBanner/>


<Route path="/pages/*" pathHide="/pages/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={$permissions.bulk?bulk:false}

      bind:filter filters={[
        { filter: {}, title:'g--All' },
        { filter: {valid:true}, title:'g--In use' },
        { filter: {compliant:true}, title:'g--compliant' },
        { filter: {valid:false}, title:'g--Draft' },
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'controller', title:'g--By controller'},
        { id:'date', title:'g--By date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--pages" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:'public', title:'g--Public'},
      {id:"status", title:"g--Status"},
      {id:"valid", title:"g--In use"},
      {id:"name", title:"g--Name"},
      {id:"tags", title:"g--Tags", format:"tags"},
      {id:"date", title:"g--Last change", format:'shortTimeSecond'}
    ]}/>
  </div>
</Route>


<Route path="/pages/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="page" detail={obj.name} back="/pages"/>
      
      <div class="anchor" on:click={anchor}>
        <span><I18n>General</I18n></span> |
        <span><I18n>Services</I18n></span> |
        <span><I18n>Design</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="General"/>
          <Input set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="reference"/>
          <Input bind:value={obj.name} required type="text" icon="fa-input-text" label="Page name" multilang translator/>
          <Input bind:value={obj.public} set={true} lg type="switch" icon="fa-globe" label="public" option="yes"/>
          <Input bind:value={obj.valid} set={true} lg type="switch" icon="fa-play-circle" label="active" option="yes"/>
          <Input bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>
          <Input bind:value={obj.supportUrl} type="url" icon="fa-link" label="support url"/>
          <Input bind:value={obj.supportEmail} type="email" icon label="support email"/>
          <Input bind:value={obj.description} type="textarea" rows=6 icon="fa-comment" label="Page description" multilang translator/>
 
          <Input type="hr" icon="fa-cogs" label="Services"/>
          <Input inCol="col-md-4" set={[]} bind:value={obj.services} type="switch" required icon="fa-cogs" label="Services" options={services}/>

          <Input type="hr" icon="fa-palette" label="Design"/>
          <Input class="col-sm-4" set="" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif" type="file" label="g--Logo" icon/>
          <Input class="col-sm-4" set="#FFFFFF" bind:value={obj.style.color} type="color" smooth colorText label="g--color"/>
          <Input class="col-sm-4" set={$conf.style.brandColor2} bind:value={obj.style.bgColor} type="color" smooth colorText label="g--bgColor"/>
          <Input class="col-sm-4" set={$conf.style.brandColor} bind:value={obj.style.buttonBgColor} type="color" smooth colorText label="g--buttonBgColor"/>
          <Input class="col-sm-4" set="#000000" bind:value={obj.style.buttonTextColor} type="color" smooth colorText label="g--buttonTextColor"/>

          <Input bind:value={obj.fqdn} type="fqdn" icon="fa-link" label="Dedicated domain"/>

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>