<script>
  import { conf, permissions, modal, lang  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor, objToArrayOfObj } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={page:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.subscribers) $modal={
      cancel:true,
      title:i18n("licence--No more subscribers"), 
      msg:i18n("licence--No more subscribers msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/subscribers/edit")
  }

  async function bulk(){
    setRoute("/subscribers/bulk")
  }

  async function edit(id){
    setRoute(`/subscribers/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/subscribers/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/subscribers")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/subscribers/get/${id}`)
    obj={ ...obj }
  }

  isSame("listSubscribers","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listSubscribers", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/subscribers/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/subscribers/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/subscribers")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    //tags = await api('/app/customers/subscribers/tags/list')
  }

  let pages=[]
  getPages()
  async function getPages(){
    pages = await api(`/app/customers/pages/list`)
  }
  function getPage(id){
    let ret
    ret=getById(pages,id)
    return i18n(ret.name || '-', $lang)
  }
</script>


<CustomerBanner/>


<Route path="/subscribers/*" pathHide="/subscribers/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={$permissions.bulk?bulk:false}

      bind:sortBy sortBys={[
        { id:'page', title:'g--By page'},
        { id:'email', title:'g--By email'},
        { id:'sms', title:'g--By sms'},
        { id:'lang', title:'g--By lang'},
        { id:'date', title:'g--By date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--subscribers" {objs} fields={[
      {id:'email', title:'g--Email'},
      {id:"lang", title:"g--Lang"},
      {id:"sms", title:"g--Sms"},
      {id:"page", title:"g--Page", format: getPage}, 
      {id:"date", title:"g--Update date", format:'dmy'},
    ]}/>
  </div>
</Route>


<Route path="/subscribers/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="subscriber" detail={obj.email + '  ' + obj.sms} back="/subscribers"/>
            
      <div class="fsScroll">
        <Form>
          <Input type="hr" icon="fa-info-square" label="Infos"/>
          <Input set={[]} bind:value={obj.page} type="select" required icon="fa-memo" label="Page" options={pages}/>

          <Input bind:value={obj.sms} type="tel" icon label="sms"/>
          <Input bind:value={obj.email} type="email" icon label="email"/>
          <Input set={$lang} bind:value={obj.lang} type="select" required icon="fa-language" label="Lang" options={objToArrayOfObj($conf.langNames)}/>

         

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </Form>
      </div>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>