<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import InputsGroup from './G_InputsGroup.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/events/edit")
  }

  async function bulk(){
    setRoute("/events/bulk")
  }

  async function edit(id){
    setRoute(`/events/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/events/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/events")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/events/get/${id}`)
    obj={ ...{logs:[], ...obj }}
  }

  isSame("listEvents","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listEvents", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/events/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/events/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/events")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/events/tags/list')
  }

  let services=[]
  getServices()
  async function getServices(){
    services = await api(`/app/customers/services/list`)
  }
</script>


<CustomerBanner/>


<Route path="/events/*" pathHide="/events/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={$permissions.bulk?bulk:false}

      bind:filter filters={[
        { filter: {}, title:'g--All' },
        { filter: {status:"published"}, title:'g--status' },
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'By name'},
        { id:'status', title:'By status'},
        { id:'type', title:'By type'},
        { id:'startDate', title:'By start date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--events" {objs} fields={[
      {id:'shortId', title:'Reference'},
      {id:"status", title:"Status"},
      {id:"type", title:"Type"},
      {id:"startDate", title:"start date", format:'shortTimeSecond'},
      {id:"name", title:"Name"},
      {id:"tags", title:"g--Tags", format:"tags"},
      {id:"date", title:"g--Update date", format:'shortTimeSecond'},
    ]}/>
  </div>
</Route>

<Route path="/events/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="event" detail={obj.name} back="/events"/>
      
      <div class="anchor" on:click={anchor}>
        <span><I18n>General</I18n></span> |
        <span><I18n>Status</I18n></span> 
        <span><I18n>Services</I18n></span> |
        <span><I18n>Logs</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="General"/>
          <Input class="col-md-2" set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="reference"/>
          <Input class="col-md-6" bind:value={obj.name} required type="text" icon="fa-input-text" label="Event name" multilang translator/>
          <Input class="col-md-2" bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>
          <Input class="col-md-2" bind:value={obj.valid} set={true} lg type="switch" icon="fa-play-circle" label="active" option="yes"/>
          
          <Input class="col-md-6" inline bind:value={obj.status} set="draft" type="radio" icon="fa-traffic-light" label="Event status" options={[ 
            { value:"draft", label: "draft"},
            { value:"published", label: "published"},
            { value:"closed", label: "closed"}
          ]}/>
          <Input class="col-md-6" inline bind:value={obj.type} set="outage" type="radio" icon="fa-traffic-light" label="Event type" options={[ 
            { value:"outage", label: "outage"},
            { value:"maintenance", label: "maintenance"}
          ]}/>

          <Input set={new Date()} class="col-md-6" bind:value={obj.startDate} icon type="datetime" label="Start date" required/>
          {#if obj.status!="closed"}
            <Input class="col-md-6" set={30} bind:value={obj.expectedDuration} type="number" icon label="Expected Duration"/>
          {/if}
          {#if obj.status=="closed"}
            <Input set={new Date()} class="col-md-6" bind:value={obj.endDate} icon type="datetime" label="End date" required/>
          {/if}
          <Input bind:value={obj.description} type="textarea" rows=3 icon="fa-comment" label="Event description" multilang translator/>

          <Input type="hr" icon="fa-info-square" label="Services"/>

          <Input inCol="col-md-4" set={[]} bind:value={obj.services} type="switch" required icon="fa-cogs" label="Impacted services" options={services}/>


          <Input type="hr" icon="fa-cog" label="Logs"/>
          <InputsGroup let:i bind:values={obj.logs} minInputs=0>
            <Input type="hidden" bind:value={obj.logs[i].id} />
            <Input set={new Date()} class="col-sm-3" bind:value={obj.logs[i].date} icon type="datetime" label="Date" required/>
            <Input bind:value={obj.logs[i].message} type="textarea" rows=6 icon="fa-comment" label="Message" required multilang translator/>
          </InputsGroup>

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>